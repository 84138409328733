import * as React from "react";
import PageHeader from "../../components/page-header";
import styled from "styled-components";

const Company = () => {
  return (
    <div>
      <PageHeader title="COMPANY" imgSrc="/company-header.jpeg" />
      <Wrapper>
        <Table>
          <tbody>
            <Tr>
              <TdKey>会社名</TdKey>
              <TdValue>Antyba合同会社</TdValue>
            </Tr>
            <Tr>
              <TdKey>設立</TdKey>
              <TdValue>2019年7月</TdValue>
            </Tr>
            <Tr>
              <TdKey>住所</TdKey>
              <TdValue>東京都板橋区中板橋22-2 ステーションサイドK 401号室</TdValue>
            </Tr>
            <Tr>
              <TdKey>従業員数</TdKey>
              <TdValue>4名</TdValue>
            </Tr>
            <Tr>
              <TdKey>代表</TdKey>
              <TdValue>北畠知行</TdValue>
            </Tr>
            <Tr>
              <TdKey>連絡先</TdKey>
              <TdValue>info@antyba.com</TdValue>
            </Tr>
            <Tr>
              <TdKey>主要取引先</TdKey>
              <TdValue>
                <div>合同会社DMM.com</div>
                <div>株式会社DMM RESORTS</div>
                <div>宇宙航空研究開発機構 JAXA</div>
                <div>株式会社stu</div>
                <div>株式会社八咫烏</div>
                <div>株式会社トライアド</div>
                <div>株式会社ピクルス</div>
                <div>株式会社THD</div>
              </TdValue>
            </Tr>
          </tbody>
        </Table>
      </Wrapper>
    </div>
  );
};

export default Company;

const Wrapper = styled.div`
  margin-top: 50px;
`;

const Table = styled.table`
  margin: auto;
`;

const Tr = styled.tr`
  height: 40px;
`;

const TdKey = styled.td`
  width: 110px;
  vertical-align: top;
  font-weight: 700;
`;

const TdValue = styled.td`
  vertical-align: top;
`;

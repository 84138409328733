import * as React from "react";
import Layout from "../presentation/components/layout";
import Top from "../presentation/containers/top";
import Company from "../presentation/containers/company";
import Head from "../presentation/components/head";

// markup
const CompanyPage = () => {
  return (
    <>
      <Head
        subtitle="Company"
        description="Antyba合同会社の基本情報を掲載しています。"
      />
      <Layout>
        <Company />
      </Layout>
    </>
  );
};

export default CompanyPage;

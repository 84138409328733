import * as React from "react";
import styled from "styled-components";

const PageHeader = (props: { title: string; imgSrc: string }) => {
  return (
    <Header imgSrc={props.imgSrc}>
      <HeaderTitle>{props.title}</HeaderTitle>
    </Header>
  );
};

export default PageHeader;

const headerHeight = 480;

const Header = styled.div<{ imgSrc: string }>`
  width: 100%;
  height: ${`${headerHeight}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const HeaderTitle = styled.div`
  font-size: 32px;
  color: white;
  letter-spacing: 0.1em;
`;
